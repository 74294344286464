<template>
  <div class="login-wrapper">
    <div class="popup">
      <div>
        <i class="el-icon-loading"></i>
        <p>正在登录...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {}
  },
  computed: {
    ...mapState(['routerName'])
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.st.user.isLogin) {
        this.$router.push({ name: this.routerName })
      }
      let code = this.getQueryString('code')
      if (code) {
        this.st.user.getToken(code)
          .then(res => {
            res
              ? this.getUserInfo()
              : this.st.user.logout()
          })
          .catch(() => {
            this.st.user.auth()
          })
      } else {
        this.st.user.auth()
      }
    },
    getUserInfo () {
      this.st.user.getUserInfo().then(res => {
        if (res) {
          this.$store.dispatch('setUserInfo', res)
          let url = window.location.href.split('?')
          window.location.href = `${ url[0] }#/${ this.routerName }`
        }
      }).catch(() => {})
    },
    getQueryString (name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
